import { transferToNative } from "@src/lib/WebAppBridge";
import axios, { AxiosError } from "axios";
import { axiosPath } from "./axiosPath";
import { useTokenStore } from "@src/store/MapStore";

const basePath = process.env.PUBLIC_URL;
export const createJWTToken = (token) => {
    return `Bearer ${token}`;
};
const tokenValue = typeof window !== "undefined" && localStorage.getItem("accessToken");
let abortController = new AbortController();
let signal = abortController.signal;

const getToken = async () => {
    axios
        .post(`${axiosPath.login}`, {
            id: "t5@msga.msg",
            passwd: "missgo237",
        })
        .then((res) => {
            let payload = res.data.payload;
            const { accessToken, refreshToken } = payload;
            localStorage.setItem("isSubscription", "true");
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
        })
        .catch((e) => alert(e));
};

let axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json",
        Authorization: createJWTToken(tokenValue),
    },
    signal,
    timeout: 3000,
});
const onMSGAApiCancelHandler = () => {
    abortController.abort();
    abortController = new AbortController();
    signal = abortController.signal;
    const tokenValue = localStorage.getItem("accessToken");
    axiosInstance = axios.create({
        headers: {
            "Content-Type": "application/json",
            Authorization: createJWTToken(tokenValue),
        },
        signal,
        timeout: 3000,
    });
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        config.headers.Authorization = createJWTToken(token);
        return config;
    },
    (error) => {
        let res = error.response.data;
        let errCode = res.code.toString();
        console.log("errCode", errCode);
        if (errCode === "401") {
            transferToNative("done", "onLoad");
            return;
        }
        return Promise.reject(error);
    },
);
axiosInstance.interceptors.response.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        config.headers.Authorization = createJWTToken(token);
        return config;
    },
    async (error) => {
        let res = error.response?.data;
        let errCode = res?.statusCode?.toString();
        let errConfig = error.config;
        if (
            errConfig?.method === "put" &&
            errConfig?.url.includes("/campus/user/class") &&
            errConfig?.data?.includes("curriculumNo") &&
            error?.response?.status === undefined
        ) {
            return Promise.reject(error);
        }
        if (errConfig?.url?.includes("datamap/getPolygon")) {
            return Promise.reject(error);
        }
        if (errConfig?.url?.includes("datamap/getStateLandInfo")) {
            return Promise.reject(error);
        }
        if (errCode === "401" && !errConfig._retry) {
            errConfig._retry = true;

            const refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken) {
                const res = await axios.post(`${axiosPath.getAccessToken}`, {
                    loginType: "web",
                    refreshToken: refreshToken,
                });

                const accessToken = createJWTToken(res.data.accessToken);

                axiosInstance.defaults.headers.common.Authorization = accessToken;
                localStorage.setItem("accessToken", res.data.accessToken);
                return axiosInstance(errConfig);
            }
        } else if (errCode === "401" && errConfig._retry) {
            transferToNative("done", "onLoad");
            return;
        }
        if (error.code === "ECONNABORTED") {
            axios.request(error.config);
            return;
        }
        if (
            error?.response?.data?.message?.includes("약관에 따라 국•공유지 필터 사용 시 구독 환불이 불가합니다.") &&
            errConfig?.url?.includes("/user/subs/refund/amount")
        ) {
            return;
        }
        if (
            error?.response?.data?.message?.includes("인벤토리가 존재하지 않습니다") &&
            errConfig?.url?.includes("/user/inventory")
        ) {
            return Promise.reject(error);
        }
        if (errConfig?.url?.includes("/user/subs") && errConfig?.method === "get") {
            return Promise.resolve(error);
        }
        if (errConfig?.url.includes("/deungi/alarm") && errConfig?.method === "post") {
            return Promise.resolve(res);
        }
        if (errConfig.url.includes("ser/ai-recommend-setting") && errConfig.method === "get") {
            return Promise.resolve(res);
        }
        axios.isCancel(error) ? console.log("요청 취소") : transferToNative(error.code, "passError");
        return Promise.reject(error);
    },
);

export { axiosInstance, onMSGAApiCancelHandler };
