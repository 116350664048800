import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import hideFilter from "@src/assets/hideFilter.png";
import showFilter from "@src/assets/showFilter.png";
import searchBlackIcon from "@src/assets/ico_search_black_bold.svg";
import ico_filter_white from "@src/assets/ico_filter_white.png";
import { Link, useSearchParams } from "react-router-dom";
import { Common } from "@src/styles/Common";
import ToastMessage from "@src/components/filter/ToastMessage";
import ResetFilterButton from "@src/components/filter/ResetFilterButton";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useNewFilterMapCheckStore, useNewFilterStore } from "@src/store/newFilterStore";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import DueDateFilter from "./DueDateFilter";
import EstPriceFilter from "./EstPriceFilter";
import MinPriceFilter from "./MinPriceFilter";
import AiResetConfirmModal from "../aiFilter/AiResetConfirmModal";
import AuctionTypeFilter from "./AuctionTypeFilter";
import NewLocationFilter from "./NewLocationFilter";
import LandUseFilter from "./LandUseFilter";
import AuctionMasterItemFilter, { auctionMasterItemList } from "./AuctionMasterItemFilter";
import AuctionStatusFilter, { auctionStatusList, findKeyByValueFromStatus } from "./AuctionStatusFilter";
import AuctionSkipFilter, { auctionSkipList } from "./AuctionSkipFilter";
import NewFilterHistory from "./NewFilterHistory";
import AuctionCounterForceFilter from "./AuctionCounterForceFilter";
import ItemsSearchInput from "@src/components/Item/ItemsSearchInput";
import ico_filter_gray from "@src/assets/ico_filter_gray.png";
import { useNewItemFilterCheckStore, useNewItemFilterStore } from "@src/store/newItemFilterStore";
import NewItemFilterHistory from "./NewItemFilterHistory";
import { FilterParamsType } from "@src/store/filterStore";

export interface IFilterParamsType {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force: number;
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    sort: number;
    auction_master_except: boolean;
}

export const initialFilterParams: IFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2, 5],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};

const check = {
    type: "경공매 구분(단일값)(0: 전체, 1: 경매, 2: 공매)",
    loc: "소재지(전국: [], 그 외: 소재지의 cortarNo 배열)[]",
    use: "용도(주거용: A~, 토지: B~, 산업용: C~, 상업용: D~, 기타: E01)[]",
    counter_force: "대항력(0: 전체, 1: 대항력 있음, 2: 대항력 없음)",
    auction_master_item:
        "경매고수물건(기존 경매고수물건과 제외물건 합쳐진 필터)(1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경)[]",
    auction_master_except: "경매고수물건 제외 여부(true: 제외, false: 포함)",
    status: "진행상태(0: 전체, 1: 진행예정, 2: 진행중, 3: 매각, 4: 기타)[]",
    skp_type: "유찰구분(0: 전체, 1: 신건, 2: 유찰)",
    skp_cnt: "유찰횟수[]",
    due_date: "매각기일(날짜 하나만 선택한 경우, 둘다 같은 날짜로 전달)[]",
    est_price: "감정가[]",
    min_price: "최저입찰가[]",
    sales_method: "처분방식(0: 전체, 1: 매각, 2: 임대)",
    assets: "자산구분(0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산)[]",
    sort: "정렬(0: 업데이트순, 1: 감정가 높은순, 2: 감정가 낮은순, 3: 최저입찰가 높은순, 4: 최저입찰가 낮은순, 5: 매각기일 가까운순, 6: 매각기일 먼순, 7: 유찰횟수 많은순 8: 유찰횟수 적은순)",
};

interface Props {
    from?: string;
    text?: string;
    isShowFilter: boolean;
    setIsShowFilter: Dispatch<SetStateAction<boolean>>;
    searchInputValue: string;
    setSearchInputValue: (SearchKeyword: string) => void;
    isFocusSearch: boolean;
    setIsFocusSearch: Dispatch<SetStateAction<boolean>>;
    setIsPushKeyword: Dispatch<SetStateAction<boolean>>;
    isPushKeyword: boolean;
    fetchData: (type: string) => void;
    isDisableFilter: boolean;
    openOutFilter: string;
    setOpenOutFilter: (val: string) => void | any;
    setSelectedSido?: (val: string) => void;
    selectedSido?: string;
}

const NewItemFilter: React.FC<Props> = ({
    from = "map",
    text = "",
    isShowFilter,
    setIsShowFilter,
    searchInputValue,
    setSearchInputValue,
    isFocusSearch,
    setIsFocusSearch,
    setIsPushKeyword,
    isPushKeyword,
    fetchData,
    openOutFilter,
    setOpenOutFilter,
    isDisableFilter,
    selectedSido,
    setSelectedSido,
}) => {
    const [searchParam, setSearchParam] = useSearchParams();
    const filterParams = useNewFilterStore((state) => state.newFilterState);
    const setFilterParams = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const tabButtons = [
        {
            title: "최근검색필터",
            key: "history",
            selected: false,
            selectedChild: "",
        },
        {
            title: "진행상태",
            key: "status",
            selected: !!filterParams.status.length,
            selectedChild:
                filterParams.status.filter((status) => status !== 2).length > 1
                    ? `${findKeyByValueFromStatus(filterParams.status[0])} 외 ${
                          filterParams.status.filter((status) => status !== 2).length - 1
                      }`
                    : `${findKeyByValueFromStatus(filterParams.status[0])}`,
        },
        {
            title: "소재지",
            key: "loc",
            selected: selectedSido !== "all" || filterParams.loc.length > 0,
            selectedChild: "소재지",
        },
        {
            title: "경공매",
            key: "type",
            selected: !!filterParams.type,
            selectedChild: filterParams.type === 1 ? "경매" : "공매",
        },
        {
            title: "용도",
            key: "use",
            selected: !!filterParams.use.length,
            selectedChild: "용도",
        },
        {
            title: "대항력",
            key: "counter_force",
            selected: !!filterParams.counter_force,
            selectedChild:
                filterParams.counter_force === 2 ? "대항력 있음" : filterParams.counter_force === 1 ? "대항력 없음" : "미상",
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: !!filterParams.auction_master_item.length,
            selectedChild:
                filterParams.auction_master_item.length > 1
                    ? `${auctionMasterItemList[filterParams.auction_master_item[0]]} 외 ${
                          filterParams.auction_master_item.length - 1
                      }${filterParams.auction_master_except ? " 제외" : ""}`
                    : `${auctionMasterItemList[filterParams.auction_master_item[0]]}${
                          filterParams.auction_master_except ? " 제외" : ""
                      }`,
        },
        {
            title: "유찰횟수",
            key: "skp_type",
            selected: !!filterParams.skp_type,
            selectedChild:
                `${auctionSkipList[filterParams.skp_type]}` +
                `${filterParams.skp_type === 2 ? ` ${filterParams.skp_cnt[0]}` : ""}` +
                `${filterParams.skp_cnt.some((val) => val) && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1] ? " ~" : ""}` +
                `${
                    filterParams.skp_type === 2 && filterParams.skp_cnt[0] !== filterParams.skp_cnt[1]
                        ? ` ${filterParams.skp_cnt[1]}`
                        : ""
                }`,
        },
        {
            title: "매각기일",
            key: "due_date",
            selected: !!filterParams.due_date.every((val) => val),
            selectedChild:
                filterParams.due_date[0] !== filterParams.due_date[1]
                    ? `${filterParams.due_date[0].slice(2)} ~ ${filterParams.due_date[1].slice(2)}`
                    : `${filterParams.due_date[0].slice(2)}`,
        },
        {
            title: "감정가",
            key: "est_price",
            selected: !!filterParams.est_price.some((val) => val),
            selectedChild:
                `감정가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value === (filterParams.est_price[0] === "30000000000" ? "higher" : filterParams.est_price[0]),
                    )?.[0]?.showText ?? ""
                } ~ ` +
                `${
                    filterParams.est_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterParams.est_price[1])?.[0]?.title
                }`,
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: !!filterParams.min_price.some((val) => val),
            selectedChild:
                `최저입찰가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value === (filterParams.min_price[0] === "30000000000" ? "higher" : filterParams.min_price[0]),
                    )?.[0]?.showText ?? ""
                } ~ ` +
                `${
                    filterParams.min_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterParams.min_price[1])?.[0]?.title
                }`,
        },
    ];

    const onClickDone = () => {
        setFilterCheck(false);
        setOpenOutFilter("");
    };

    useEffect(() => {
        if (searchParam.get("close")) {
            onClickDone();
            const currentParams = Object.fromEntries(searchParam);
            delete currentParams?.close;
            setSearchParam(currentParams);
        }
    }, [searchParam.get("close")]);

    useEffect(() => {
        if (sessionStorage.getItem("isApplyUserPosition") === "true") {
            sessionStorage.setItem("isApplyUserPosition", "false");
        } else {
            delete filterParams.userLocation;
        }
    }, [filterParams]);

    useEffect(() => {
        if (from !== "map") {
            // fetchInitialFilterParams();
        }
    }, []);

    return (
        <div css={rootStyle(!!openOutFilter)}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <ItemsSearchInput
                    isShowFilter={isShowFilter}
                    setIsShowFilter={setIsShowFilter}
                    searchInputValue={searchInputValue}
                    setSearchInputValue={setSearchInputValue}
                    isFocusSearch={isFocusSearch}
                    setIsFocusSearch={setIsFocusSearch}
                    setIsPushKeyword={setIsPushKeyword}
                    isPushKeyword={isPushKeyword}
                    fetchData={(type: string) => fetchData(type)}
                    // setItems={setItems}
                    // tryGetItemList={tryGetItemList}
                />
                <div css={tabListBoxStyle(isShowFilter)}>
                    {!isShowFilter ? (
                        <div css={DisableAllFilter}>
                            <button>
                                <img src={ico_filter_gray} />
                                <span>전체 필터</span>
                            </button>
                        </div>
                    ) : (
                        <Link to="/newMainFilter?from=item" css={allFilterButtonStyle} onClick={() => setFilterCheck(true)}>
                            <img src={ico_filter_white} />
                            <span>전체 필터</span>
                        </Link>
                    )}
                    <div className="filter_button_scroll_wrapper">
                        {tabButtons.map((item, index) => (
                            <TabButton
                                isSelected={openOutFilter === item.key || item.selected}
                                disabled={!isShowFilter}
                                key={`${item}_${index}`}
                                onClick={() => {
                                    if (openOutFilter === item.key) {
                                        onClickDone();
                                    } else {
                                        setFilterCheck(true);
                                        setOpenOutFilter(item.key);
                                    }
                                }}
                            >
                                <span>{item.selected ? item.selectedChild : item.title}</span>
                            </TabButton>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                {openOutFilter && (
                    <>
                        <div css={dividerStyle} />
                        <div css={whiteDividerStyle} />
                    </>
                )}
                {openOutFilter === "history" && (
                    <NewFilterHistory
                        onClose={() => {
                            setFilterCheck(false);
                            setOpenOutFilter("");
                        }}
                    />
                )}
                {openOutFilter === "type" && (
                    <AuctionTypeFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "loc" && (
                    <NewLocationFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        showToast={() => setToastVisible(true)}
                        from="out"
                        outSelectedSido={selectedSido}
                        setOutSelectedSido={(val: string) => setSelectedSido !== undefined && setSelectedSido(val)}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "use" && (
                    <LandUseFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        from="out"
                        showToast={() => setToastVisible(true)}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "counter_force" && (
                    <AuctionCounterForceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "auction_master_item" && (
                    <AuctionMasterItemFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "status" && (
                    <AuctionStatusFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "skp_type" && (
                    <AuctionSkipFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "due_date" && (
                    <DueDateFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "est_price" && (
                    <EstPriceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "min_price" && (
                    <MinPriceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter && openOutFilter !== "history" && (
                    <ResetFilterButton onResetClick={() => setResetModalVisible(true)} onConfirmClick={onClickDone} />
                )}
            </div>
            <ToastMessage visible={toastVisible} setToastVisible={() => setToastVisible(false)}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={() => {
                        setFilterParams(initialFilterParams);
                        setResetModalVisible(false);
                        setSelectedSido && setSelectedSido("all");
                    }}
                />
            )}
            {openOutFilter && <div css={{ height: "100%", background: "rgba(0,0,0,1)", opacity: 0.3 }} onClick={onClickDone} />}
        </div>
    );
};

export default NewItemFilter;

const rootStyle = (isShowFilterList: boolean) => css`
    width: 100vw;
    height: ${isShowFilterList ? "calc(var(--vh, 1vh) * 100)" : "fit-content"};
    height: ${isShowFilterList ? "100dvh" : "fit-content"};
    height: ${isShowFilterList ? "100vh" : "fit-content"};
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 14px 14px 0;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255, 255, 1)"};
`;

const inputBoxStyle = css`
    position: relative;
    width: 100%;
    height: 44px;
    padding: 4px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const inputStyle = css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    opacity: 1;
    height: 40px;
    border: 1px solid #e3e3e6;
    border-radius: 8px;
    padding: 0px 10px 0px 48px;
    font-size: 16px;
    background: #ffffff;
    &:focus {
        outline: none;
    }
`;

const searchImageStyle = css`
    position: absolute;
    left: 58px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
`;

const tabListBoxStyle = (isShowFilter: boolean) => css`
    display: flex;
    align-items: center;
    height: ${isShowFilter ? "56px" : "56px"};
    opacity: ${isShowFilter ? 1 : 1};
    visibility: ${isShowFilter ? "visible" : "visible"};
    /* height: ${isShowFilter ? "56px" : "0px"};
    opacity: ${isShowFilter ? 1 : 0};
    visibility: ${isShowFilter ? "visible" : "hidden"}; */
    transition: all 0.2s ease;
    z-index: 3;
    button + button {
        margin-left: 8px;
    }
    & > a {
        & > button {
            opacity: ${isShowFilter ? 1 : 0};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${isShowFilter ? "visible" : "hidden"};
            height: ${isShowFilter ? "30px !important" : "0px"};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${isShowFilter ? "32px" : "32px"};
        opacity: ${isShowFilter ? 1 : 1};
        visibility: ${isShowFilter ? "visible" : "visible"};
        /* height: ${isShowFilter ? "32px" : "0px"};
        opacity: ${isShowFilter ? 1 : 0};
        visibility: ${isShowFilter ? "visible" : "hidden"}; */
        transition: all 0.2s ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const allFilterButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }
    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const dividerStyle = css`
    height: 12px;
    background: rgb(236, 239, 244);
`;

const whiteDividerStyle = css`
    height: 10px;
    background-color: #ffffff;
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled }) => (disabled ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled }) =>
        disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled }) =>
            disabled ? `${Common.colors.grey04}` : isSelected ? ` ${Common.colors.white}` : `${Common.colors.normalTextColor}`};
    }
`;

const DisableAllFilter = css`
    display: flex;
    height: 32px;
    /* background: ${Common.colors.grey02}; */
    min-width: 102px;
    border-radius: 24px;
    & > button {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: ${Common.colors.grey02};
        min-width: 102px;
        border-radius: 24px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.grey04};
    }

    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;
